.ui-page-theme-a .ui-bar-inherit{
    background: #8338ec !important;
}
.ui-header .ui-title{
    font-size: 16px !important;
    color: #fff !important;
}
.ui-content .h2-default {
    font-size: 12px !important;
    color: #000 !important;
}


.ui-mobile [data-role=page] .ui-popup-content {
    position: relative;
}
.ui-mobile [data-role=page] .terminate-chat-popup,
.ui-mobile [data-role=page] .start-chat-popup,
.ui-mobile [data-role=page] .accept-video-chat-popup {
    background-color: #fff;
}
.terminate-chat-popup .five9-cancel-btn,
.thank-you a.five9-link-btn.start-over-button{
    color: #000 !important;
    border-radius: 3px !important;
    border: 1px solid #ddd !important;
    padding: 7px 10px;
    font-weight: 600 !important;
    font-size: 14px !important;
    text-decoration: none;
    margin: 9px 0 0 0 !important;
}
.terminate-chat-popup .five9-confirm-btn,
.thank-you #footer-close-button.five9-link-btn{
    background-color: #db002c;
    color: #fff !important;
    border-radius: 3px !important;
    border: 1px solid #db002c !important;
    padding: 7px 10px;
    font-weight: 600 !important;
    font-size: 14px !important;
    text-decoration: none;
    padding: 7px 10px;
}
#conversation-page .ui-footer.expanded .end-who {
    margin: -10px 0 15px 0;
}